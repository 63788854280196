<template>
  <div class="historical">
    <div
      class="historical-labels"
    >
      <information-card
        :icon="absenceIcon"
        :title="absenceStudentTitle"
      />
    </div>
    <v-list
      class="historical-list"
    >
      <v-list-item-group
        v-model="selected"
      >
        <student-absences-list-item
          v-for="(student, index) in absencesStudentsList"
          :key="index"
          :student-absence="student"
          @click="seeStudentsOccurrence"
        />
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import InformationCard from '@/components/cards/Information.vue';
import StudentAbsencesListItem from '@/components/lists/StudentAbsences.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PresenceHistorical',
  components: {
    InformationCard,
    StudentAbsencesListItem,
  },
  data() {
    return {
      attendanceTitle: 'Assiduidade da classe',
      attendanceIcon: 'poll',
      absenceStudentTitle: 'Alunos com mais faltas',
      absenceIcon: 'person',
      selected: '',
      studentsList: {},
      absencesStudentsList: [],
    };
  },
  computed: {
    ...mapGetters([
      'getSchoolClass',
    ]),
    changeAttendanceTitle() {
      return this.attendanceTitle;
    },
    studentsListInfos() {
      return this.studentsList;
    },
  },
  watch: {
    getSchoolClass(value) {
      this.studentsList = value;
      this.absencesStudentsList = this.sortList(value.students);
    },
  },
  mounted() {
    this.findSchoolClass({ schoolClassId: this.getSchoolClass.id });
  },
  methods: {
    ...mapActions([
      'findSchoolClass',
    ]),
    seeStudentsOccurrence(studentSelected) {
      this.$router.push({
        name: 'historicalOccurrences',
        params: {
          backPath: 'presenceHistorical',
          studentId: studentSelected.id,
          schoolClassId: this.getSchoolClass.id,
          socioemotional: false,
        },
      });
    },
    sortList(listObject) {
      const sortedObject = listObject.sort(
        (a, b) => b.absences_count.toString().localeCompare(a.absences_count.toString()),
      );
      return sortedObject;
    },
  },
};
</script>
<style lang="scss">
.historical {
  padding-top: 16px;

  .historical-labels {
    padding: 0 16px 0 16px;
    margin: 0;
  }

  .historical-list {
    @include font-monteserrat-semi-bold;
    font-size: $font-size-extra-small;

    .v-list-item {
      padding: 0 20px 0px 20px;

      &:nth-child(even) {
        background: $list-background-color;
      }

      &:nth-child(odd) {
        background: $list-background-secondary-color;
      }
    }

    .v-list-item__content {
      padding: 16px 0;
    }

    .v-list-item__title {
      color: $text-color-secondary;
      font-size: $font-size-extra-small;
    }

    .v-list-item__action-text {
      color: $text-color-secondary;
      font-size: $font-size-extra-small;
    }
  }
}
</style>
