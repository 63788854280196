<template>
  <div
    v-if="hasOccurrences"
    class="student-occurrence"
  >
    <v-list-item
      inactive
      class="pa-0"
    >
      <v-list-item-content>
        <v-list-item-title class="occurrence-title d-flex">
          <div>
            {{ categoriesTitle }}
          </div>
          <span class="date">{{ formattedDate(studentOccurrence.occurrence_date) }}</span>
        </v-list-item-title>

        <v-list-item-subtitle class="occurrence-subtitles">
          <span
            v-for="(criterium, index) in studentOccurrence[occurrenceKind]"
            :key="criterium.occurrence_criterium"
          >
            {{ criterium.occurrence_criterium }}
            <span v-if="criterium.score">
              - {{ criterium.score }}
            </span>
            <br v-if="hasSeparator(index)">
          </span>
        </v-list-item-subtitle>

        <v-list-item-subtitle class="occurrence-subtitles">
          <span>
            {{ studentOccurrence.student_name }} - {{ studentOccurrence.school_class_name }}
          </span>
        </v-list-item-subtitle>

        <div class="occurrence-description">
          <span>
            {{ studentOccurrence.description }}
          </span>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { parseISO, format } from 'date-fns';

export default {
  name: 'StudentOccurrenceListItem',
  props: {
    occurrence: {
      type: Object,
      default: () => {},
    },
    occurrenceKind: {
      type: String,
      default: 'student_occurrence_criterium',
    },
  },
  data() {
    return {
      studentOccurrence: this.occurrence,
    };
  },
  computed: {
    ...mapGetters([
      'getToolbarText',
    ]),
    categoriesTitle() {
      const categories = [];
      this.studentOccurrence[this.occurrenceKind].forEach((elem) => {
        if (categories.includes(elem.occurrence_category)) return;
        categories.push(elem.occurrence_category);
      });

      const lastCategory = categories.splice(-1);
      return categories.length
        ? [categories.join(', '), lastCategory].join(' e ')
        : lastCategory[0];
    },
    studentSchoolClass() {
      return this.getToolbarText;
    },
    hasOccurrences() {
      return this.studentOccurrence[this.occurrenceKind].length > 0;
    },
  },
  watch: {
    occurrence(value) {
      this.studentOccurrence = value;
    },
  },
  methods: {
    hasSeparator(index) {
      return index + 1 < this.studentOccurrence[this.occurrenceKind].length;
    },
    formattedDate(date) {
      const parsedDate = parseISO(date);
      return format(parsedDate, 'dd/MM/yyyy');
    },
  },
};
</script>

<style lang="scss">
  .student-occurrence {
    .occurrence-title {
      @include font-open-sans-semi-bold;
      white-space: normal;
      font-size: $font-size-extra-small;
      color: $text-color-primary;
      line-height: 16px;
      margin-bottom: 4px;

      .date {
        color: $text-color-secondary;
        margin-right: 1px;
        margin-left: auto;
      }

      .separator {
        margin-right: 4px;
      }
    }

    .occurrence-subtitles {
      @include font-open-sans-bold;
      font-size: $font-size-extra-small;
      line-height: 16px;
      margin-bottom: 4px;

      span {
        color: $text-color-secondary;
      }
    }

    .occurrence-description {
      @include font-open-sans-regular;
      font-size: $font-size-extra-small;
      color: $text-color-secondary;
      line-height: 16px;
      margin-top: 4px;
    }
  }
</style>
