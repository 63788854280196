<template>
  <div class="historical-occurrences">
    <span class="occurrences-title">
      {{ $t('views.historical.occurrences') }}
    </span>
    <v-list
      v-if="hasOccurrences"
      class="pt-0"
    >
      <v-list-item-group>
        <student-occurrence-list-item
          v-for="(studentOccurrence, index) in studentOccurrencesSorted"
          :key="index"
          :occurrence="studentOccurrence"
          :occurrence-kind="occurrenceKind"
        />
      </v-list-item-group>
    </v-list>
    <div
      v-else
      class="no-occurrence-placeholder"
    >
      <v-icon
        class="no-occurrence-icon"
      >
        report_off
      </v-icon>
      <p class="mt-2">
        {{ $t('views.historical.no_occurrences') }}
      </p>
    </div>
  </div>
</template>

<script>
import StudentOccurrenceListItem from '@/components/lists/StudentOccurrence.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'HistoricalOccurrences',
  components: {
    StudentOccurrenceListItem,
  },
  data() {
    return {
      studentOccurrences: [],
    };
  },
  computed: {
    ...mapGetters([
      'getStudentOccurrences',
    ]),
    hasOccurrences() {
      return this.studentOccurrences.length > 0;
    },
    studentOccurrencesSorted() {
      return this.sortList(this.studentOccurrences);
    },
    occurrenceKind() {
      return this.$route.params.socioemotional ? 'student_occurrence_socioemo' : 'student_occurrence_criterium';
    },
  },
  watch: {
    getStudentOccurrences(value) {
      this.studentOccurrences = value;
    },
  },
  created() {
    const params = this.$route.query;
    this.findStudentOccurrences({
      ...params,
      by_student: this.$route.params.studentId,
    });
  },
  methods: {
    ...mapActions([
      'findStudentOccurrences',
    ]),
    sortList(listObject) {
      const sortedObject = listObject.sort(
        (a, b) => new Date(b.occurrence_date) - new Date(a.occurrence_date),
      );
      return sortedObject;
    },
  },
};
</script>

<style lang="scss">
  .historical-occurrences {
    padding: 16px;

    .occurrences-title {
      @include font-open-sans-bold;
      font-size: $font-size-extra-small;
      color: $titles-color-primary;
      line-height: 16px;
      margin-bottom: 8px;
    }

    .no-occurrence-placeholder {
      margin-top: 30px;
      @include font-open-sans-bold;
      text-align: center;
      color: $text-color-secondary;

      .no-occurrence-icon {
        font-size: 40px;
      }
    }
  }
</style>
