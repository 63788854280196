<template>
  <v-list-item
    @click="studentSelected"
  >
    <v-list-item-content>
      <v-list-item-title v-text="student.name" />
    </v-list-item-content>

    <v-list-item-action>
      <v-list-item-action-text>
        {{ student.absences_count }} {{ $t('views.historical.absences') }}
      </v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'StudentAbsencesListItem',
  props: {
    studentAbsence: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      student: this.studentAbsence,
      selected: '',
    };
  },
  methods: {
    studentSelected() {
      this.$emit('click', this.student);
    },
  },
};
</script>
